import React from 'react';
import { Grid } from '@vaimo-int/aem-pwa-common-components';
import { ITilesGridProps } from './types';
import mergeTilesIntoProducts from './utils/mergeTilesIntoProducts';

const TilesGrid = ({ productList, tiles }: ITilesGridProps) => {
    const itemsToRender = mergeTilesIntoProducts({
        products: productList,
        tiles,
    });

    return <Grid.Row>{itemsToRender}</Grid.Row>;
};

export default TilesGrid;
