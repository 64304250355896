import React, { ReactElement } from 'react';
import { MergeTilesIntoProducts } from '../types';
import TileCard from '../modules/tileCard';

export const mergeTilesIntoProducts = ({ products, tiles }: MergeTilesIntoProducts): Array<ReactElement> => {
    if (!tiles?.mobileTiles.length) {
        return products;
    }

    if (!products?.length) {
        return [];
    }

    const firstProductIndex = products?.[0]?.props?.index;
    const lastProductIndex = products?.[products.length - 1]?.props?.index;

    //Performing a tile set correction for the current set of products
    const mobileTiles = tiles.mobileTiles.filter(
        (tile) => tile.position >= firstProductIndex && tile.position <= lastProductIndex,
    );

    const desktopTiles = tiles.desktopTiles.filter(
        (tile) => tile.position >= firstProductIndex && tile.position <= lastProductIndex,
    );

    const tilesWithMergedPositions = mobileTiles.map((mobileTile, index) => {
        return (
            <TileCard
                key={`tile-${mobileTile.position}`}
                bg_color={mobileTile.bg_color}
                bg_image_url={mobileTile.bg_image_url}
                button_1_link={mobileTile.button_1_link}
                button_1_text={mobileTile.button_1_text}
                button_2_link={mobileTile.button_2_link}
                button_2_text={mobileTile.button_2_text}
                desktopColumnPosition={desktopTiles[index].columnPosition}
                desktopRowPosition={desktopTiles[index].rowPosition}
                mobileColumnPosition={mobileTile.columnPosition}
                mobileRowPosition={mobileTile.rowPosition}
                position={mobileTile.position}
                size_of_two_desktop={mobileTile.size_of_two_desktop}
                size_of_two_mobile={mobileTile.size_of_two_mobile}
                subcopy={mobileTile.subcopy}
                subtitle={mobileTile.subtitle}
                title={mobileTile.title}
            />
        );
    });

    const result = [...products, ...tilesWithMergedPositions];

    return result;
};

export default mergeTilesIntoProducts;
