import { gql } from '@apollo/client';

export const CategoryTilesFragment = gql`
    fragment CategoryTilesFragment on CategoryTree {
        tiles {
            position
            size_of_two_desktop
            size_of_two_mobile
            subtitle
            title
            subcopy
            bg_color
            bg_image_url
            button_1_text
            button_1_link
            button_2_text
            button_2_link
        }
    }
`;
