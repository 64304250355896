import React, { ReactElement } from 'react';
import { useFilterBlock } from './hooks/useFilterBlock';
import { Accordion2 as Accordion } from '@vaimo-int/aem-pwa-common-components';
import FilterList from '../FilterList';
import FilterPreview from '../FilterPreview';
import type { IFilterBlockProps } from '../FilterBar/types';

import classes from './filterBlock.module.scss';

const FilterBlock = ({
    fhrId,
    filters,
    handleScrollToFilterBar,
    search,
    startTransition,
}: IFilterBlockProps): ReactElement | null => {
    const {
        facetNavigationList,
        filterItems,
        filterNames,
        filterState,
        getNextSearchQuery,
        handleClickOnItem,
        handleReset,
    } = useFilterBlock({
        fhrId,
        filters,
        handleScrollToFilterBar,
        search,
        startTransition,
    });

    const filtersList = Array.from(filterItems, ([group, items]) => {
        const blockState = filterState.get(group);
        const title = filterNames.get(group);

        return {
            content: (
                <div className={classes.list} data-mt-type="filter-block-list">
                    <FilterList
                        filterState={blockState}
                        group={group}
                        isFacetNavigationEnabled={facetNavigationList.includes(group)}
                        handleClick={handleClickOnItem}
                        getNextSearchQuery={getNextSearchQuery}
                        items={items}
                        key={group}
                    />
                </div>
            ),
            title,
        };
    });

    return (
        <div className={!filterState.size ? classes.filterWrapper : ''} data-mt-type="filter-block">
            <FilterPreview filterState={filterState} handleClick={handleClickOnItem} handleReset={handleReset} />
            <Accordion isFilterAccordion isOpenByDefault items={filtersList} />
        </div>
    );
};

export default FilterBlock;
