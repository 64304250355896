import productBadges from './productBadgeList.json';
import { isNaStore } from '@/integrations/optimizely/src/migration/utils';
import { IProduct } from '@/components/RootComponents/Category/modules/ProductCard/types';

export const getProductBadge = (sku: string, storeCode: string): string | undefined => {
    if (!sku || storeCode === 'en_global') return;

    const skuNumber = Number(sku);
    if (isNaStore(storeCode)) {
        const isFrenchRegion = storeCode === 'fr_ca';

        if (productBadges.NA.skiFree.includes(skuNumber)) return 'Ski Free';
        if (productBadges.NA.bestsellers.includes(skuNumber)) {
            return isFrenchRegion ? 'Best-Seller' : 'Best Seller';
        }
        if (productBadges.NA.testedByPros.includes(skuNumber)) {
            return isFrenchRegion ? 'Testè par des Pros' : 'Tested by Pros';
        }
        if (productBadges.NA.newStyle.includes(skuNumber)) {
            return isFrenchRegion ? 'Nouveau Style' : 'New Style';
        }
        if (productBadges.NA.newColour.includes(skuNumber)) {
            return isFrenchRegion ? 'Nouvelle' : 'New';
        }
    } else if (storeCode.startsWith('en_')) {
        if (productBadges.EU.testedByPros.includes(skuNumber)) return 'Tested by Pros';
        if (productBadges.EU.bestsellers.includes(skuNumber)) return 'Best Seller';
        if (productBadges.EU.newStyle.includes(skuNumber)) return 'New Style';
    }

    return;
};

export const applyProductBadgesToList = (items: Array<IProduct>, storeCode: string): Array<IProduct> => {
    if (!items) return items;

    return [...items].map((product) => {
        return {
            ...product,
            badge: getProductBadge(product.sku, storeCode),
        };
    });
};
