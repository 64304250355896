import { useContext, useEffect, useMemo, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { convertObjectToSearchParams, getSearchFromState, getStateFromSearch, stripHtml } from '../utils/helpers';
import { useSelectFilter } from '@vaimo/google-tag-manager';
import { useFredHopper } from '@/components/RootComponents/Category/hooks/useFredHopper';
import type { IFilterBlockProps, ItemType } from '@/components/RootComponents/Category/modules/FilterBar/types';
import { CategoryContext } from '@/components/RootComponents/Category/context/categoryContext';
import { useAppContext } from '@/lib/context';
import type { IFilter } from '@/components/RootComponents/Category/types';

const PARAMS_LIST = ['q', 'ct'];

const getItemsByGroup = (filters: IFilter[]) => {
    const names = new Map();
    const keys = new Set();
    const itemsByGroup = new Map();

    if (filters?.length) {
        for (const filter of filters) {
            const { attribute_code: group, label: name, options } = filter;
            const items = [];
            names.set(group, name);
            keys.add(group);

            for (const { count, default_label, label, value, value_id } of options) {
                items.push({
                    color: group === 'color_group' && (default_label || label),
                    count,
                    title: stripHtml(label),
                    value,
                    value_id,
                });
            }
            itemsByGroup.set(group, items);
        }
    }

    return { itemsByGroup, keys, names };
};

const getFacetNavigationList = (
    faceted_navigation: string | undefined,
    filterState: Map<string, any>,
): Array<string> => {
    if (!faceted_navigation || filterState.size > 0) {
        return [];
    }

    return faceted_navigation.split(',');
};

const useFilterBlockOriginal = ({
    fhrId,
    filters,
    handleScrollToFilterBar,
    search,
    startTransition,
}: IFilterBlockProps) => {
    const router = useRouter();
    const pathname = usePathname();
    const searchParams = convertObjectToSearchParams(search);
    const { selectFilter } = useSelectFilter();
    const { commonData, getFacets } = useFredHopper(fhrId);
    const { faceted_navigation } = useContext(CategoryContext);

    const { itemsByGroup, keys, names } = useMemo(() => getItemsByGroup(filters), [filters]);

    const filterState = getStateFromSearch(searchParams?.toString() || '', keys, itemsByGroup);

    const handleReset = () => {
        const params = convertObjectToSearchParams(search);

        Object.keys(search || {}).map((key) => {
            if (!PARAMS_LIST.includes(key)) {
                params.delete(key);
            }
        });

        params.set('p', '1');

        startTransition(() => router.push(`${pathname}?${params.toString()}`, { scroll: false }));
        handleScrollToFilterBar();
    };

    const getNextSearchQuery = (group: string, item: ItemType) => {
        const nextState = new Map(filterState);
        const nextSet = new Set(filterState.get(group));
        const params = convertObjectToSearchParams(search);

        if (params?.has('p')) {
            params.delete('p');
        }

        if (nextSet.has(item)) {
            nextSet.delete(item);
        } else {
            nextSet.add(item);
        }

        if (nextSet.size) {
            nextState.set(group, nextSet);
        } else {
            nextState.delete(group);
        }

        return getSearchFromState(params?.toString() || '', keys, nextState);
    };

    const handleClickOnItem = (group: string, item: ItemType) => {
        const nextSearch = getNextSearchQuery(group, item);

        selectFilter({
            ...commonData,
            ...getFacets({
                filtersData: filters,
                nextSearch,
            }),
        });

        startTransition(() => router.push(pathname + nextSearch, { scroll: false }));
        handleScrollToFilterBar();
    };

    return {
        facetNavigationList: getFacetNavigationList(faceted_navigation, filterState),
        filterItems: itemsByGroup,
        filterKeys: keys,
        filterNames: names,
        filterState,
        getNextSearchQuery,
        handleClickOnItem,
        handleReset,
    };
};

const useFilterBlockVariantB = ({
    fhrId,
    filters,
    handleScrollToFilterBar,
    isFlexibleFilter,
    search,
    startTransition,
}: IFilterBlockProps) => {
    const router = useRouter();
    const pathname = usePathname();
    const searchParams = convertObjectToSearchParams(search);
    const { selectFilter } = useSelectFilter();
    const { commonData, getFacets } = useFredHopper(fhrId);
    const { faceted_navigation, isPending } = useContext(CategoryContext);
    const [filterState, setFilterState] = useState(new Map());

    const { itemsByGroup, keys, names } = useMemo(() => getItemsByGroup(filters), [filters]);

    useEffect(() => {
        const nextState = getStateFromSearch(searchParams?.toString() || '', keys, itemsByGroup);

        setFilterState(nextState);
    }, [keys, itemsByGroup]);

    const handleReset = () => {
        const params = convertObjectToSearchParams(search);

        Object.keys(search || {}).map((key) => {
            if (!PARAMS_LIST.includes(key)) {
                params.delete(key);
            }
        });

        startTransition(() => router.push(`${pathname}?${params.toString()}`, { scroll: false }));
        handleScrollToFilterBar();
    };

    const getNextState = (group: string, item: ItemType) => {
        const nextState = new Map(filterState);
        const nextSet = new Set(filterState.get(group));

        if (nextSet.has(item)) {
            nextSet.delete(item);
        } else {
            nextSet.add(item);
        }

        if (nextSet.size) {
            nextState.set(group, nextSet);
        } else {
            nextState.delete(group);
        }

        return nextState;
    };

    const getNextSearchQuery = (group: string, item: ItemType): string => {
        const nextState = getNextState(group, item);

        return getSearchFromState(convertObjectToSearchParams(search).toString(), keys, nextState, isFlexibleFilter);
    };

    const handleClickOnItem = (group: string, item: ItemType) => {
        const nextState = getNextState(group, item);
        const nextSearch = getNextSearchQuery(group, item);

        selectFilter({
            ...commonData,
            ...getFacets({
                filtersData: filters,
                nextSearch,
            }),
        });

        setFilterState(nextState);

        if (!isPending) {
            startTransition(() => {
                router.push(pathname + nextSearch, { scroll: false });
            });
        } else {
            router.replace(pathname + nextSearch, { scroll: false });
        }

        handleScrollToFilterBar();
    };

    return {
        facetNavigationList: getFacetNavigationList(faceted_navigation, filterState),
        filterItems: itemsByGroup,
        filterKeys: keys,
        filterNames: names,
        filterState,
        getNextSearchQuery,
        handleClickOnItem,
        handleReset,
    };
};

export const useFilterBlock = ({
    fhrId,
    filters,
    handleScrollToFilterBar,
    search,
    startTransition,
}: IFilterBlockProps) => {
    const [
        {
            storeConfig: { isFlexibleFilter },
        },
    ] = useAppContext();

    const variantA = useFilterBlockOriginal({
        fhrId,
        filters,
        handleScrollToFilterBar,
        search,
        startTransition,
    });
    const variantB = useFilterBlockVariantB({
        fhrId,
        filters,
        handleScrollToFilterBar,
        isFlexibleFilter,
        search,
        startTransition,
    });

    return isFlexibleFilter ? variantB : variantA;
};
