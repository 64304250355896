import { gql } from '@apollo/client';

export const ProductSwatchesFragment = gql`
    fragment ProductSwatchesFragment on ProductInterface {
        ... on ConfigurableProduct {
            showSkiFreeBanner: show_ski_free_banner
            configurable_options {
                attribute_code
                attribute_id
                id
                uid
                label
                values {
                    uid
                    default_label
                    label
                    store_label
                    use_default_value
                    value_index
                    swatch_data {
                        ... on ImageSwatchData {
                            thumbnail
                        }
                        value
                    }
                }
            }
            variants {
                attributes {
                    code
                    color_group
                    value_index
                    uid
                }
                product {
                    # id is deprecated and unused in our code, but lint rules require we
                    # request it if available
                    id
                    uid
                    only_x_left_in_stock
                    media_gallery_entries {
                        # id is deprecated and unused in our code, but lint rules require we
                        # request it if available
                        id
                        uid
                        disabled
                        file
                        label
                        position
                        media_type
                        video_content {
                            media_type
                            video_provider
                            video_url
                            video_title
                            video_description
                            video_metadata
                        }
                    }
                    name
                    sku
                    stock_status
                    special_price
                    thumbnail {
                        url
                    }
                    price_range {
                        maximum_price {
                            final_price {
                                value
                                currency
                            }
                            regular_price {
                                value
                                currency
                            }
                            discount {
                                amount_off
                            }
                        }
                        minimum_price {
                            final_price {
                                value
                                currency
                            }
                            regular_price {
                                value
                                currency
                            }
                            discount {
                                amount_off
                            }
                        }
                    }
                    lowest_price_data {
                        lowest_price {
                            value
                            currency
                        }
                        campaign_title
                        campaign_content
                    }
                }
            }
        }
    }
`;
