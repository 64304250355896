import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { ButtonSelector, Icon } from '@vaimo-int/aem-pwa-common-components';
import { SIZE_GROUP } from '@/constants/listingData';
import { useAppContext } from '@/lib/context';
import type { ItemType } from '@/components/RootComponents/Category/modules/FilterBar/types';

import classes from './filterItem.module.scss';
import checkboxStyles from '@vaimo-int/aem-pwa-common-components/dist/components/Checkbox/styles/checkbox.module.scss';

interface IFilterItem {
    filterState: Set<any>;
    getNextSearchQuery: (group: string, item: ItemType) => string;
    group: string;
    handleClick: (group: string, item: ItemType) => void;
    isFacetNavigationEnabled: boolean;
    item: ItemType;
}

const FilterItem = ({
    filterState,
    getNextSearchQuery,
    group,
    handleClick,
    isFacetNavigationEnabled,
    item,
}: IFilterItem) => {
    const { color, title, value } = item;
    const isLightColor = color && ['white', 'beige', 'yellow'].includes(color.toLowerCase());
    const isSelected = filterState?.size ? filterState.has(item) : false;
    const [
        {
            storeConfig: { isFlexibleFilter },
        },
    ] = useAppContext();

    const Wrapper = (props: HTMLAttributes<HTMLLabelElement | HTMLAnchorElement>) =>
        isFacetNavigationEnabled ? (
            <a
                href={getNextSearchQuery(group, item)}
                onClick={(e) => {
                    // Work around to avoid general navigation animation
                    e.preventDefault();
                    e.stopPropagation();
                    handleClick(group, item);
                }}
                {...props}
            />
        ) : (
            <label onClick={(e) => (isFlexibleFilter && e.preventDefault(), handleClick(group, item))} {...props} />
        );

    return group === SIZE_GROUP ? (
        <ButtonSelector type="button" label={title} active={isSelected} onClick={() => handleClick(group, item)} />
    ) : (
        <Wrapper className={clsx(classes.filterItem, checkboxStyles.label, color && classes.filterItemColor)}>
            <input
                type={'checkbox'}
                className={clsx(checkboxStyles.field, color && checkboxStyles.fieldHide)}
                name={group}
                value={`${title}-${value}`}
                onChange={(e) => e.target.value}
                checked={isSelected}
            />
            {color && (
                <span
                    className={clsx(checkboxStyles.iconHolder, isSelected && checkboxStyles.iconHolderActive)}
                    style={{ backgroundColor: `${color}` }}
                >
                    <Icon
                        className={clsx(
                            checkboxStyles.icon,
                            isSelected && checkboxStyles.iconActive,
                            isLightColor && checkboxStyles.iconLightColor,
                        )}
                        name={'checkmark'}
                        size={'18px'}
                    />
                </span>
            )}
            {title}
        </Wrapper>
    );
};

export default FilterItem;
