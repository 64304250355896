import React from 'react';
import clsx from 'clsx';
import { ITileCard, TilesCSSProperties } from '../types';
import { generateImageProps } from '@/components/OptimizedImage/utils/generateImageProps';
import { transparentPlaceholder1x1 } from '@/components/OptimizedImage/utils/images';
import { Image, Button2 as Button } from '@vaimo-int/aem-pwa-common-components';
import {
    IMAGE_SIZE,
    IMAGE_WIDTHS,
    SIZES_NEW_GRID,
} from '@/components/RootComponents/Category/modules/ProductCard/hooks/useProductCard';

import classes from '../styles/tile.module.scss';

const TileCard = ({
    bg_color,
    bg_image_url,
    button_1_link,
    button_1_text,
    button_2_link,
    button_2_text,
    desktopColumnPosition,
    desktopRowPosition,
    mobileColumnPosition,
    mobileRowPosition,
    size_of_two_desktop,
    size_of_two_mobile,
    subcopy,
    subtitle,
    title,
}: ITileCard) => {
    const imageProps = generateImageProps({
        height: IMAGE_SIZE,
        isOptimizedByFastly: true,
        sizesSet: IMAGE_WIDTHS,
        src: bg_image_url || '',
        width: IMAGE_SIZE,
        widths: SIZES_NEW_GRID,
    });

    const customStyles: TilesCSSProperties = {
        '--desktop-tile-column-position': desktopColumnPosition,
        '--desktop-tile-row-position': desktopRowPosition,
        '--mobile-tile-column-position': mobileColumnPosition,
        '--mobile-tile-row-position': mobileRowPosition,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.size_of_two_desktop]: size_of_two_desktop,
                [classes.size_of_two_mobile]: size_of_two_mobile,
            })}
            style={customStyles}
        >
            <div className={classes.wrapper} style={{ background: bg_color ?? undefined }}>
                {bg_image_url && (
                    <Image
                        image={{
                            ...imageProps,
                            alt: 'tile-background-image',
                            dimensionsType: 'auto',
                            fileReference: bg_image_url,
                            placeholder: transparentPlaceholder1x1,
                            url: undefined,
                        }}
                        classes={{
                            image: clsx(classes.backgroundImage, {
                                [classes.size_of_two_desktop]: size_of_two_desktop,
                                [classes.size_of_two_mobile]: size_of_two_mobile,
                            }),
                        }}
                    />
                )}

                <div
                    className={clsx(classes.contentWrapper, {
                        [classes.size_of_two_desktop]: size_of_two_desktop,
                        [classes.size_of_two_mobile]: size_of_two_mobile,
                    })}
                >
                    <div className={classes.content} style={{ background: bg_color ?? undefined }}>
                        <p className={classes.subtitle}>{subtitle}</p>
                        <p className={classes.title}>{title}</p>
                        {subcopy && (
                            <p className={clsx(classes.subcopy, { [classes.size_of_two_mobile]: size_of_two_mobile })}>
                                {subcopy}
                            </p>
                        )}

                        <div
                            className={clsx(classes.buttonsContainer, {
                                [classes.withOneButton]: !button_2_link,
                                [classes.size_of_two_mobile]: size_of_two_mobile,
                            })}
                        >
                            <Button
                                variant="secondary"
                                to={button_1_link}
                                className={clsx(classes.link, { [classes.withOneButton]: !button_2_link })}
                            >
                                {button_1_text}
                            </Button>

                            {button_2_link && (
                                <Button variant="secondary" to={button_2_link} className={classes.link}>
                                    {button_2_text}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TileCard;
